<template>
  <div>
    <dv-full-screen-container>
      <div class="bg">

        <div class="buju">
          <!--左侧-->
          <div style="margin-top: 50px;">
            <div class="tianqi">
              <!--<span style="margin-right: 15px;">多云转晴</span>-->
              <!--<span style="margin-right: 15px;">17-22℃</span>-->
              <!--<span>东南风3级</span>-->
            </div>

            <!--季度目标完成-->
            <div class="mubiao">
              <span style="font-size: 20px;font-weight: 700;color: #ffffff;">季度目标完成</span>
              <span style="opacity: 0.6;font-size: 14px;font-weight: 400;color: #ffffff;">Seasonable Goal </span>
            </div>
            <div style="margin-top: 20px;">
              <!--<dv-border-box-8 :reverse="true">-->
              <!--  -->
              <!--</dv-border-box-8>-->
              <div style="display: flex;justify-content: space-around;">
                <dv-active-ring-chart :config="config" style="width:200px;height:200px" />
              </div>
              <div style="text-align: center;">
                <span style="width: 10px;height: 10px;background: #00a7ff;display: inline-block;margin-right: 10px;"></span>
                <span style="font-size: 16px;font-weight: 400;color: #e9ffff;margin-right: 10px;">季度销售额</span>
                <span style="font-size: 30px;font-weight: 700;color: #effaff;">{{quota.actual_payment}}</span>
                <!--<span style="font-size: 12px;font-weight: 400;color: #ffffff;margin-right: 10px;">万元</span>-->
                <span style="opacity: 0.8;font-size: 12px;font-weight: 400;text-align: left;margin-left: 10px;">同比增长</span>
                <span style="margin-left: 10px;">
                  <img src="@/assets/images/daping/zengzhang.png" alt="" style="width: 4px;height: 9px;">
                  <span style="font-size: 12px;font-weight: 500;color: #ff463c;">{{quota.growth}}%</span>
                </span>
              </div>

              <!--客户销售排行-->
              <div class="mubiao">
                <span style="font-size: 20px;font-weight: 700;color: #ffffff;">客户销售排行</span>
                <span style="opacity: 0.6;font-size: 14px;font-weight: 400;color: #ffffff;">Customer sales </span>
              </div>
              <div style="margin-top: 20px;" v-if="show">
                <dv-capsule-chart :config="Salesconfig" style="width:300px;height:150px" />
              </div>

              <!--运力分布-->
              <div class="mubiao">
                <span style="font-size: 20px;font-weight: 700;color: #ffffff;">运力分布</span>
                <span style="opacity: 0.6;font-size: 14px;font-weight: 400;color: #ffffff;">Capacity distribution</span>
              </div>
              <div style="margin-top: 20px;">
                <TransportCapacity :institution_code="institution_code"/>
              </div>
            </div>

          </div>

          <!--中间-->
          <div style="width: 50%;">
            <div style="display: flex;justify-content: space-around;">
              <span style="font-size: 48px;font-weight: 400;color: #ffffff;letter-spacing: 7.2px;overflow: hidden;text-overflow: ellipsis;white-space: nowrap;">{{title}}运营大屏</span>
            </div>
            <div style="margin-top: 30px;">
              <Center  @title="queryTiele"/>
            </div>
          </div>

          <!--右侧-->
          <div style="margin-top: 30px;">
            <div class="time">
              <!--<span>2023-03-15  09：15：36</span>-->
              <span>{{time}}</span>
            </div>
            <!--服务项目占比-->
            <div class="mubiao">
              <span style="font-size: 20px;font-weight: 700;color: #ffffff;">服务项目占比</span>
              <span style="opacity: 0.6;font-size: 14px;font-weight: 400;color: #ffffff;">Service Items</span>
            </div>
            <div style="margin-top: 20px;">
              <div>
                <ProportionOfServiceItems />
              </div>
            </div>

            <!--每月销售趋势-->
            <div class="mubiao">
              <span style="font-size: 20px;font-weight: 700;color: #ffffff;">每月销售趋势</span>
              <span style="opacity: 0.6;font-size: 14px;font-weight: 400;color: #ffffff;">Monthly sales</span>
            </div>
            <div style="margin-top: 20px;">
              <div>
                <SalesTrends />
              </div>
            </div>

            <!--城市销售排行榜-->
            <div class="mubiao">
              <span style="font-size: 20px;font-weight: 700;color: #ffffff;">城市销售排行榜</span>
              <span style="opacity: 0.6;font-size: 14px;font-weight: 400;color: #ffffff;">Energy consumption </span>
            </div>
            <div style="margin-top: 20px;">
              <div>
                <UrbanSales />
              </div>
            </div>
          </div>
        </div>
      </div>
    </dv-full-screen-container>
  </div>
</template>

<script>
//引入运力分布组件
import TransportCapacity from './components/transportCapacity.vue'
//引入服务项目占比
import ProportionOfServiceItems from './components/proportionOfServiceItems.vue'
//引入销售趋势
import SalesTrends from './components/salesTrends.vue'
//引入城市销售排行榜
import UrbanSales from './components/urbanSales.vue'
//引入中间部分
import Center from './components/center.vue'
import {quarter_sale, sales_rank,replacement} from "@/api/yunli";

export default {
  components:{
    TransportCapacity,
    ProportionOfServiceItems,
    SalesTrends,
    UrbanSales,
    Center
  },
  data(){
    return{
      // 公司名称
      title:'',
      // 机构吗
      institution_code: localStorage.getItem('institution_code'),

      quota:{},
      config:{
        color:['#B2EFFF','#139AFF'],
        data:[
          {
            // name: '周口',
            value: 55
          },
          {
            // name: '南阳',
            value: 120
          },
        ],
        activeTimeGap:1000,

      },

      // 销售排行
      show:false,
      Salesconfig:{
        data: [
          // {
          //   name: 'NO.1',
          //   value: 167
          // },
          // {
          //   name: 'NO.2',
          //   value: 67
          // },
          // {
          //   name: 'NO.3',
          //   value: 123
          // },
          // {
          //   name: 'NO.4',
          //   value: 55
          // },
          // {
          //   name: 'NO.5',
          //   value: 98
          // }
        ],
        colors: ['#2FF2E3', '#2FF2E3', '#2FF2E3', '#2FF2E3', '#2FF2E3'],
        // unit: '单位',
        // 显示数值
        showValue: true
      },

      option:{
        legend: {
          data: ['同比', '环比']
        },
        radar: {
          polygon: true,
          indicator: [
            { name: '西峡', max: 300 },
            { name: '周口', max: 300 },
            { name: '南阳', max: 300 },
            { name: '驻马店', max: 300 },
            { name: '郑州', max: 300 },
            { name: '洛阳', max: 300 }
          ]
        },
        series: [
          {
            name: '同比',
            type: 'radar',
            data: [111, 256, 178, 152, 266, 132],
            label: {
              show: false
            },
            animationCurve: 'easeOutBounce'
          },
          {
            name: '环比',
            type: 'radar',
            data: [222, 245, 220, 130, 240, 100],
            label: {
              show: false
            },
            animationCurve: 'easeOutBounce'
          }
        ]
      },

      // 时间
      time: new Date().toLocaleString()

    }
  },

  mounted() {
    // 时间计算
    var self = this
    setInterval(function() {
      self.time = new Date().toLocaleString()
    }, 1000)

    if(localStorage.getItem('institution_code') == '' || localStorage.getItem('institution_code') == undefined || localStorage.getItem('institution_code') == null){
      console.log(123465123)
      this.getCode()
    }else {
      console.log(456456465)
      this.queryTiele();
      this.getquarter_sale();
      this.getsales_rank();
    }
  },


  methods:{
    // 置换机构码
    getCode(){
      replacement().then(res => {
        // 机构码
        this.institution_code = res.data.institution_code;
        localStorage.setItem('institution_code',res.data.institution_code);

        this.queryTiele();

        this.getquarter_sale();

        this.getsales_rank();
      })
    },

    //获取公司名称
    queryTiele(value){
      // console.log(value);
      this.title = value;
    },

    //回去季度目标完成
    getquarter_sale(){
      quarter_sale(localStorage.getItem('institution_code')).then(res => {
        this.quota = res.data;
      })
    },

    //获取客户销售排行
    getsales_rank(){
      sales_rank(localStorage.getItem('institution_code')).then(res => {
        // console.log(res);
        this.Salesconfig.data = [];
        res.data.cooperationList.forEach(item => {
          item.actual_payment = parseInt(item.actual_payment);
          item.name = item.second_institution_code;
          item.value = item.actual_payment;
          let where = {
            name:item.company_name,
            value: item.actual_payment
          }
          this.Salesconfig.data.push(where);
        })
        this.show = true;
      })
    },

  },

}
</script>

<style lang="scss" scoped>
/*清除默认边距*/
*{
  margin: 0;
  padding: 0;
}
/*设置html标签高度为100%，body高度为100%*/
html,body{
  height: 100%;
}
//背景图片
.bg{
  width: 100%;
  height: 100%;
  background-image: url("../../assets/images/daping/bg.png");
  background-size: 100% 100%;
  background-repeat: no-repeat;
}

.buju{
  display: flex;
  justify-content: space-around;
  align-items: stretch;
}

//左侧
.mubiao{
  width: 367px;
  height: 41px;
  background-image: url("../../assets/images/daping/wancheng.png");
  background-size: 100% 100%;
  background-repeat: no-repeat;
  display: flex;
  justify-content: space-evenly;
  align-items: center;
  margin-top: 18px;
}
.tianqi{
  font-size: 16px;
  font-weight: 400;
  color: #c9d3db;
}
//左侧结尾


//右侧
.time{
  font-size: 30px;
  font-weight: 700;
  color: #ffffff;
  text-shadow: 0px 3px 6px 0px rgba(6,211,255,0.40);
}
//右侧结尾

</style>
