<!--大屏 城市销售排行榜-->
<template>
  <div>
    <div id="urbanSales" style="height: 200px;"></div>
  </div>
</template>

<script>
import {urban_marketing,replacement} from "@/api/yunli";

export default {
  data(){
    return{
      chart: null,
      institution_code: localStorage.getItem('institution_code')
    }
  },

  mounted() {
    if(localStorage.getItem('institution_code') == '' || localStorage.getItem('institution_code') == undefined || localStorage.getItem('institution_code') == null){
      console.log(123465123)
      this.getCode()
    }else {
      console.log(456456465)
      setTimeout(() => {
        this.getCharts();
        this.charTimg();
      }, 1000);
    }


  },

  methods:{
    // 置换机构码
    getCode(){
      replacement().then(res => {
        // 机构码
        this.institution_code = res.data.institution_code;
        localStorage.setItem('institution_code',res.data.institution_code);

        setTimeout(() => {
          this.getCharts();
          this.charTimg();
        }, 1000);
      })
    },

    charTimg() {
      // setInterval(() => {
      //   this.getCharts();
      // }, 6000);
      this.getCharts();
    },

    getCharts() {
      // const chartBox = this.$echarts.init(document.getElementById("urbanSales"));
      this.chart = this.$echarts.init(document.getElementById("urbanSales"));

      // 数据
      let dateBase = new Date();
      let dottedBase = +dateBase + 1000 * 3600 * 24;
      let weekCategory = [];

      let maxData = 200;
      let weekMaxData = [];
      let weekLineData = [];

      urban_marketing(localStorage.getItem('institution_code')).then(res => {
        // console.log(res);

        // 周数据
        for (let i = 0; i < res.data.series.length; i++) {
          // 日期
          // var date = new Date((dottedBase -= 1000 * 3600 * 24));
          // weekCategory.unshift([date.getMonth() + 1, date.getDate()].join("/"));

          // 折线图数据
          // weekMaxData.push(maxData);
          // var distance = Math.round(Math.random() * 100 + 50);
          // console.log('distance',distance)
          weekLineData.push(res.data.series[i]);
        }

        let option = {
          grid: {
            top:10,
            left:80
          },
          xAxis: {
            type: 'category',
            data: res.data.xAxis,
            position: "bottom",
            axisLine: true,
            axisLabel: {
              color: "rgba(255,255,255,.8)",
              fontSize: 12
            },
          },
          yAxis: {
            type: 'value',
            name: "",
            nameLocation: "end",
            nameGap: 24,
            nameTextStyle: {
              color: "rgba(255,255,255,.5)",
              fontSize: 14
            },
            splitNumber: 4,

            axisLine: {
              lineStyle: {
                opacity: 0
              }
            },
            splitLine: {
              show: true,
              lineStyle: {
                color: "#fff",
                opacity: 0.1
              }
            },
            axisLabel: {
              color: "rgba(255,255,255,.8)",
              fontSize: 12
            }
          },
          series: [
            {
              // data: [120, 200, 150, 80, 70, 110, 130],
              data:weekLineData,
              type: 'bar',
              gradient: {
                color: ['#37a2da', '#67e0e3']
              },
              itemStyle: { //该数值区域样式设置
                normal: {
                  color: new this.$echarts.graphic.LinearGradient(0, 0, 1, 1, [{
                    offset: 0,
                    color: '#30FBEB',
                  }, {
                    offset: 1,
                    // color: '#2EEDDE',
                    color: 'rgba(46,237,222,0.00)'
                  }]),
                }
              }
            }
          ]
        };
        this.chart.setOption(option);
      })

    },

  },

}
</script>

<style lang="scss" scoped>

</style>
