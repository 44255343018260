<template>
  <div>
    <div id="bottomRightChart" style="height: 200px;"></div>
  </div>
</template>

<script>
import echartMixins from "@/utils/resizeMixins";
import {quarteryj,replacement} from "@/api/yunli";

export default {
  data() {
    return {
      chart: null,
      institution_code: localStorage.getItem("institution_code")
    };
  },
  mounted() {
    if(localStorage.getItem('institution_code') == '' || localStorage.getItem('institution_code') == undefined || localStorage.getItem('institution_code') == null){
      console.log(123465123)
      this.getCode()
    }else {
      console.log(456456465)
      setTimeout(() => {
        this.draw();
        this.charTimg();
      }, 1000);
    }


  },
  mixins: [echartMixins],
  methods: {
    // 置换机构码
    getCode(){
      replacement().then(res => {
        // 机构码
        this.institution_code = res.data.institution_code;
        localStorage.setItem('institution_code',res.data.institution_code);

        setTimeout(() => {
          this.draw();
          this.charTimg();
        }, 1000);
      })
    },

    charTimg() {
      // setInterval(() => {
      //   this.draw();
      // }, 6000);
      this.draw();
    },
    draw() {
      // 基于准备好的dom，初始化echarts实例
      this.chart = this.$echarts.init(document.getElementById("bottomRightChart"));
      //  ----------------------------------------------------------------
      // 数据
      // let dateBase = new Date();
      // let dottedBase = +dateBase + 1000 * 3600 * 24;
      // console.log(dateBase)
      let weekCategory = [];

      let radarData = [];
      let radarDataAvg = [];
      let maxData = 200;
      let weekMaxData = [];
      let weekLineData = [];

      quarteryj(localStorage.getItem("institution_code")).then(res => {
        weekCategory = res.data.xAxis

        // 折线图数据
        // for (let i = 0; i < res.data.xAxis.length; i++){
        //   weekMaxData.push(maxData);
        //   console.log(weekMaxData)
        //   var distance = Math.round(Math.random() * 100 + 50);
        //   console.log('distance',distance)
        //   weekLineData.push(distance);
        // }

        for (let i = 0; i < res.data.series.length; i++){
          weekLineData.push(res.data.series[i]);
          // console.log(weekLineData);
        }

        // 颜色设置
        let color = {
          linearYtoG: {
            type: "linear",
            x: 0,
            y: 0,
            x2: 1,
            y2: 1,
            colorStops: [
              {
                offset: 0,
                color: "#f5b44d"
              },
              {
                offset: 1,
                color: "#28f8de"
              }
            ]
          },
          linearGtoB: {
            type: "linear",
            x: 0,
            y: 0,
            x2: 1,
            y2: 0,
            colorStops: [
              {
                offset: 0,
                color: "#43dfa2"
              },
              {
                offset: 1,
                color: "#28f8de"
              }
            ]
          },
          linearBtoG: {
            type: "linear",
            x: 0,
            y: 0,
            x2: 1,
            y2: 0,
            colorStops: [
              {
                offset: 0,
                color: "#1c98e8"
              },
              {
                offset: 1,
                color: "#28f8de"
              }
            ]
          },
          areaBtoG: {
            type: "linear",
            x: 0,
            y: 0,
            x2: 0,
            y2: 1,
            colorStops: [
              {
                offset: 0,
                color: "rgba(35,184,210,.2)"
              },
              {
                offset: 1,
                color: "rgba(35,184,210,0)"
              }
            ]
          }
        };
        let option = {
          title: {
            text: "",
            textStyle: {
              color: "#D3D6DD",
              fontSize: 24,
              fontWeight: "normal"
            },
            subtextStyle: {
              color: "#fff",
              fontSize: 16
            },
            // top: 50,
            // left: 80
          },
          tooltip: {
            trigger: "item"
          },
          grid: {
            top:10,
            left:80
          },
          xAxis: {
            type: "category",
            position: "bottom",
            axisLine: true,
            axisLabel: {
              color: "rgba(255,255,255,.8)",
              fontSize: 12
            },
            data: weekCategory
          },
          // 下方Y轴
          yAxis: {
            name: "",
            nameLocation: "end",
            nameGap: 24,
            nameTextStyle: {
              color: "rgba(255,255,255,.5)",
              fontSize: 14
            },
            // max: maxData,
            splitNumber: 4,

            axisLine: {
              lineStyle: {
                opacity: 0
              }
            },
            splitLine: {
              show: true,
              lineStyle: {
                color: "#fff",
                opacity: 0.1
              }
            },
            axisLabel: {
              color: "rgba(255,255,255,.8)",
              fontSize: 12
            }
          },
          series: [
            {
              name: "",
              type: "line",
              smooth: true,
              symbol: "emptyCircle",
              symbolSize: 8,
              itemStyle: {
                normal: {
                  color: "#fff"
                }
              },
              lineStyle: {
                normal: {
                  color: color.linearBtoG,
                  width: 3
                }
              },
              areaStyle: {
                normal: {
                  color: color.areaBtoG
                }
              },
              data: weekLineData,
              lineSmooth: true,
              markLine: {
                silent: true,
                data: [
                  {
                    type: "average",
                    name: "平均值"
                  }
                ],
                precision: 0,
                label: {
                  normal: {
                    formatter: "平均值: \n {c}"
                  }
                },
                lineStyle: {
                  normal: {
                    color: "rgba(248,211,81,.7)"
                  }
                }
              },
              tooltip: {
                position: "top",
                formatter: "{c} m",
                backgroundColor: "rgba(28,152,232,.2)",
                padding: 6
              }
            },
            {
              name: "占位背景",
              type: "bar",
              itemStyle: {
                normal: {
                  show: true,
                  color: "#000",
                  opacity: 0
                }
              },
              silent: true,
              barWidth: "50%",
              // data: weekMaxData,
              animation: false
            }
          ]
        };

        this.chart.setOption(option);


      })
    }
  },
  destroyed() {
    window.onresize = null;
  }
};
</script>

<style lang="scss" scoped>
</style>
