<!--&lt;!&ndash;大屏 销售趋势&ndash;&gt;-->
<!--<template>-->
<!--  <div>-->
<!--    <div id="salesTrends" style="height: 200px"></div>-->
<!--  </div>-->
<!--</template>-->

<!--<script>-->
<!--export default {-->
<!--  data(){-->
<!--    return{-->

<!--    }-->
<!--  },-->

<!--  mounted() {-->
<!--    this.getCharts();-->
<!--  },-->

<!--  methods:{-->
<!--    getCharts() {-->
<!--      const chartBox = this.$echarts.init(document.getElementById("salesTrends"));-->
<!--      const option = {-->
<!--        tooltip: {-->
<!--          trigger: 'axis',-->
<!--          axisPointer: {-->
<!--            type: 'cross',-->
<!--            label: {-->
<!--              backgroundColor: '#6a7985'-->
<!--            }-->
<!--          }-->
<!--        },-->
<!--        legend: {-->
<!--          data: ['Email', 'Union Ads', 'Video Ads', 'Direct', 'Search Engine']-->
<!--        },-->
<!--        toolbox: {-->
<!--          feature: {-->
<!--            saveAsImage: {}-->
<!--          }-->
<!--        },-->
<!--        grid: {-->
<!--          left: '3%',-->
<!--          right: '4%',-->
<!--          bottom: '3%',-->
<!--          containLabel: true-->
<!--        },-->
<!--        textStyle:{-->
<!--          color:'#fff'-->
<!--        },-->
<!--        xAxis: {-->
<!--          type: 'category',-->
<!--          boundaryGap: false,-->
<!--          data: ['Mon', 'Tue', 'Wed', 'Thu', 'Fri', 'Sat', 'Sun']-->
<!--        },-->
<!--        yAxis: {-->
<!--          type: 'value'-->
<!--        },-->
<!--        series: [-->
<!--          {-->
<!--            data: [820, 932, 901, 934, 1290, 1330, 1320],-->
<!--            type: 'line',-->
<!--            areaStyle: {}-->
<!--          }-->
<!--        ]-->
<!--      };-->
<!--      chartBox.setOption(option);-->
<!--      // 根据页面大小自动响应图表大小-->
<!--      window.addEventListener("resize", function () {-->
<!--        chartBox.resize();-->
<!--      });-->


<!--    },-->

<!--  },-->

<!--}-->
<!--</script>-->

<!--<style lang="scss" scoped>-->

<!--</style>-->


<template>
  <div id="bottomRight">
    <div class="bg-color-black">
      <div>
        <bottomRightChart />
      </div>
    </div>
  </div>
</template>

<script>
import bottomRightChart from "./components/bottomRightChart.vue";
export default {
  data() {
    return {
      institution_code: localStorage.getItem("institution_code")
    };
  },
  components: {
    bottomRightChart
  },
  mounted() {},
  methods: {}
};
</script>

<style lang="scss">
#bottomRight {
  padding: 0.2rem 0.2rem 0;
  height: 200px;
  min-width: 3.75rem;
  border-radius: 0.0625rem;
  .bg-color-black {
    height: 200px;
    border-radius: 0.125rem;
  }
  .text {
    color: #c3cbde;
  } //下滑线动态
  .decoration2 {
    position: absolute;
    right: 0.125rem;
  }
  .chart-box {
    margin-top: 0.2rem;
    width: 2.125rem;
    height: 2.125rem;
    .active-ring-name {
      padding-top: 0.125rem;
    }
  }
}
</style>
