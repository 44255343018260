<!--大屏 运力分布-->
<template>
  <div>
    <div id="maychar" style="height: 200px"></div>
  </div>
</template>

<script>
  import {radar} from "@/api/yunli";

  export default {
    data(){
      return{
        institution_code: localStorage.getItem('institution_code')
      }
    },

    mounted() {
      setTimeout(() => {
        this.getCharts();
      }, 1000)
    },

    methods:{
      getCharts() {
        const chartBox = this.$echarts.init(document.getElementById("maychar"));

        radar(this.institution_code).then(res => {
          console.log(res);

          res.data.xAxis.forEach(item => {
            item.text = item.city;
          })

          const option = {
            tooltip: {
              trigger: 'axis'
            },
            legend: {
              left: 'center',
              data: []
            },
            // backgroundColor:['#20646C'],
            radar: [
              {
                indicator: res.data.xAxis,
                radius: 80,
                center: ['50%', '60%'],
                axisLine: {  // 设置雷达图中间射线的颜色
                  lineStyle: {
                    color: '#c0c0c0',
                  },
                },
                splitArea : {  //设置图表颜色，show的值为true
                  show : true,
                  areaStyle : {
                    color:"#08222F", //一般设置方式
                    //设置渐变背景色 new echarts.graphic.LinearGradient(a,b,c,d,arr)
                    //a ,b,c,d值可为0，1 a:1表示arr中的颜色右到左；c:1 arr中的颜色左到右
                    //b:1表示arr中的颜色下到上；d:1表示arr中的颜色上到下
                    // color: new this.$echarts.graphic.LinearGradient(0, 1, 0, 0, [
                    //   {offset: 0, color: '#c1ddf8'}, // 0% 处的颜色
                    //   {offset: 1, color: '#1e83e4'}// 100% 处的颜色
                    // ], false)
                  }
                },
              },
            ],
            series: [
              {
                type: 'radar',
                tooltip: {
                  trigger: 'item'
                },
                areaStyle: {},
                data: [
                  {
                    value: res.data.series[0],
                    name: 'A Software',
                    itemStyle: { //该数值区域样式设置
                      normal: {
                        color: 'rgba(32,100,108,6)', //背景颜色，还需设置areaStyle
                        lineStyle: {
                          color: 'rgba(108,217,217,.10)', //边框颜色
                        },
                      },
                    }
                  }
                ]
              },
              {
                type: 'radar',
                tooltip: {
                  trigger: 'item'
                },
                areaStyle: {},
                data: [
                  {
                    value: res.data.series[1],
                    name: 'A Software',
                    itemStyle: { //该数值区域样式设置
                      normal: {
                        color: 'rgba(17,79,117,6)', //背景颜色，还需设置areaStyle
                        lineStyle: {
                          color: 'rgba(34,155,235,.10)', //边框颜色
                        },
                      },
                    }
                  }
                ]
              },
            ]
          };
          chartBox.setOption(option);
          // 根据页面大小自动响应图表大小
          window.addEventListener("resize", function () {
            chartBox.resize();
          });

        })

      },

    },

  }
</script>

<style lang="scss" scoped>

</style>
